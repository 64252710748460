import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    $(".copy-link").on("click", function(e) {
      e.preventDefault();

      const url = $(this).attr("href");

      navigator.clipboard.writeText(url).then(function() {
        alert("URL copied to clipboard!");
      }).catch(function(err) {
        console.error("Failed to copy URL: ", err);
      });
    });
  }
}
